export const sendEmail = async (email, resume) => {
	const url = 'https://gtxey05gk3.execute-api.us-east-1.amazonaws.com/default/send_email_sendgrid';
	const response = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			email: email,
			resume: resume
		})
	});
	return response.json();
};
