<script>
	import { setBackground, description } from '../../stores/stores';
	import { fade } from 'svelte/transition';
	import { clickOutside } from '../../click_outside/ClickOutside';
</script>

<div
	class="p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 opacity-95 overflow-y-auto"
	use:clickOutside
	on:click_outside={setBackground}
	in:fade={{ duration: 250 }}
	out:fade={{ duration: 250 }}
>
	<h5 class="mb-2 text-2xl font-bold tracking-tight from-text-gray-900 dark:text-white">
		{$description.title}
	</h5>
	<p class="mb-3 font-normal text-gray-700 dark:text-gray-400 overflow-y-auto">
		{$description.description}
	</p>
	<div class="flex justify-center items-center">
		<button
			on:click={setBackground}
			class="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
		>
			<span
				class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
			>
				Exit
			</span>
		</button>
	</div>
</div>
