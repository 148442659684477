<script>
	import { onMount } from 'svelte';
	import { createScene } from "./scene";

	import Home from './lib/components/home/Home.svelte';
	import Contact from './lib/components/contact/Contact.svelte';
	import Description from './lib/components/description/Description.svelte';
	import Header from './lib/components/header/Header.svelte';
	import Rotate from './lib/components/rotate/Rotate.svelte';
	import { nav } from './lib/stores/stores';

	let el;

	onMount(async () => await createScene(el));
</script>

<div class="absolute">
	<Header/>
</div>
<div class="flex h-screen">
	<div class="m-auto">
		{#if $nav === "HOME"}
			<Home />
		{/if}
		
		{#if $nav === "CONTACT"}
			<Contact />
		{/if}
		
		{#if $nav === "DESCRIPTION"}
			<Description />
		{/if}
		<br/>
		<Rotate/>
	</div>
</div>
<canvas bind:this={el}></canvas>
<style lang="postcss" global>
	@tailwind base;
	@tailwind components;
	@tailwind utilities;
	html, body { 
		height: 100%;
		overflow: hidden
	}

	canvas {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		background-color: black;
	}

  </style>
