import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { AmbientLight, PerspectiveCamera, Scene, WebGLRenderer } from "three";
import { setDescription, isLoaded } from "./lib/stores/stores";

let scene, camera, renderer, canvas;
let HEIGHT = 0,
  WIDTH = 0,
  mouseX = 0,
  mouseY = 0,
  windowHalfX = 0,
  windowHalfY = 0,
  models = [],
  gltfs = [],
  mobile = false,
  mouse = new THREE.Vector2(-1, -1);

const loader = new GLTFLoader();
const raycaster = new THREE.Raycaster();

export const createScene = async (domElement) => {
  canvas = domElement;
  await loadModels();
  init();
  animate();
};

const openDialog = (model) => {
  const modelName = model.object.name.split("_")[0];
  const modelObj = gltfs.filter((gltf) => gltf.name === modelName)[0];
  setDescription(modelObj.title, modelObj.description);
};

const setScene = () => {
  camera = new PerspectiveCamera();
  camera.position.z = 1000;
  camera.position.x = canvas.clientWidth;
  camera.position.y = canvas.clientHeight;
  camera.far = 50000;

  scene = new Scene();

  scene.add(new AmbientLight());
  models.forEach((model, i) => {
    [...Array(gltfs[i].count).keys()].forEach((i) => {
      const x = Math.random() * 2000 - 1000;
      const y = Math.random() * 2000 - 1000;
      const z = Math.random() * 2000 - 1000;
      let newModel = model.clone();
      newModel.position.set(x, y, z);
      newModel.scale.set(0.1, 0.1, 0.1);
      scene.add(newModel);
    });
  });
};

const init = (el) => {
  setScreenSize();
  setScene();
  setRenderer(el);
  setEventListeners();
};

const render = () => {
  camera.position.x += (mouseX - camera.position.x) * 0.05;
  camera.position.y += (-mouseY - camera.position.y) * 0.05;
  camera.lookAt(scene.position);
  renderer.render(scene, camera);
};

const animate = () => {
  requestAnimationFrame(animate);
  render();
};

const loadModels = async () => {
  gltfs = await fetch("static/glb_data.json").then((res) => res.json());
  const modelsToLoad = gltfs.reduce(
    (acc, { name }) => [...acc, loader.loadAsync(`static/glb/${name}.glb`)],
    []
  );
  const uncleanModels = await Promise.all(modelsToLoad);
  models = uncleanModels.map((gltf) => gltf.scene);
  isLoaded.set(true);
};

// initializers
const setRenderer = () => {
  renderer = new WebGLRenderer({ antialias: true, canvas: canvas });
  renderer.setPixelRatio(window.devicePixelRatio); /*	Probably 1; */
  renderer.setSize(WIDTH, HEIGHT); /*	Full screen */
};

const setScreenSize = () => {
  HEIGHT = window.innerHeight;
  WIDTH = window.innerWidth;
  windowHalfX = WIDTH / 2;
  windowHalfY = HEIGHT / 2;
  if (WIDTH <= 480) mobile = true;
};
const setEventListeners = () => {
  window.addEventListener("resize", onWindowResize, false);
  document.addEventListener("mousemove", onDocumentMouseMove, false);
  document.addEventListener("click", onDocumentMouseDown, false);
  document.addEventListener("touchstart", onDocumentTouchStart, false);
  document.addEventListener("touchmove", onDocumentTouchMove, false);
};
const onDocumentMouseDown = (event) => {
  let mouseVec = new THREE.Vector2();
  mouseVec.x = (event.offsetX / canvas.clientWidth) * 2 - 1;
  mouseVec.y = -(event.offsetY / canvas.clientHeight) * 2 + 1;

  raycaster.setFromCamera(mouseVec, camera);
  const hits = raycaster.intersectObjects(scene.children, true);
  if (hits.length > 0) openDialog(hits[0]);
};

const onDocumentMouseMove = (e) => {
  mouseX = e.clientX * 1.5 - windowHalfX;
  mouseY = e.clientY * 1.5 - windowHalfY;
  if (mobile) mouseX = e.clientX * 3 - windowHalfX;

  mouse.x = ((e.clientX * 1.5) / window.innerWidth) * 2 - 1;
  mouse.y = -((e.clientY * 1.5) / window.innerHeight) * 2 + 1;

  if (WIDTH <= 480) mobile = true;
  else mobile = false;
};

const onDocumentTouchStart = (e) => {
  if (e.touches.length === 1) {
    e.preventDefault();
    mouseX = e.touches[0].pageX - windowHalfX;
    mouseY = e.touches[0].pageY - windowHalfY;
  }
};

const onDocumentTouchMove = (e) => {
  if (e.touches.length === 1) {
    e.preventDefault();
    mouseX = e.touches[0].pageX - windowHalfX;
    mouseY = e.touches[0].pageY - windowHalfY;
  }
};

const onWindowResize = () => {
  windowHalfX = window.innerWidth / 2;
  windowHalfY = window.innerHeight / 2;

  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
};
