<script>
  import { onMount } from 'svelte';
  import { setHome } from '../../stores/stores'
  
  let open = false;

  onMount(() => setOpen())

  const setOpen = () => open = window.innerHeight > window.innerWidth

</script>
<svelte:window on:resize={setOpen}/>
<svelte:head>
	<link href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet">
</svelte:head>
{#if open}
<div class="flex p-4 mb-4 bg-gray-100 rounded-lg dark:bg-gray-700" role="alert">
  <span class="text-gray-700 dark:text-gray-300 material-icons">
    screen_rotation
    </span>
  <div class="ml-3 text-sm font-medium text-gray-700 dark:text-gray-300">
    Rotate phone to landscape
  </div>
  <button type="button" 
  class="ml-auto -mx-1.5 -my-1.5 bg-gray-100 text-gray-500 rounded-lg focus:ring-2 focus:ring-gray-400 p-1.5 hover:bg-gray-200 inline-flex h-8 w-8 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-800 dark:hover:text-white" 
  aria-label="Close"
  on:click={()=>{
    open = !open
    setHome()
  }}>
    <span class="sr-only">Dismiss</span>
    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
  </button>
</div>
{/if}