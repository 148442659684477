<script>
	import { setContact, setBackground,isLoaded } from '../../stores/stores';
	import { fade } from 'svelte/transition';
	import { clickOutside } from '../../click_outside/ClickOutside';
	import {Shadow} from "svelte-loading-spinners";
	import { onMount } from 'svelte';
	
	let description = "Thank you for visiting this page! Please click off this dialog to play with the background. Make sure to click on some of my favorite technologies and see what I have done with them early 2022."
	
	const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
	
	const setDescription = () => {
		if (isMobile()) 
			description = `${description} Please rotate your phone to landscape to have the best experience`
	}
	
	onMount(() => setDescription())
  
</script>


<div
	class="p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 opacity-95 overflow-y-auto"
	use:clickOutside
	on:click_outside={setBackground}
	in:fade={{ duration: 250 }}
>
	{#if $isLoaded}
		<h5 class="mb-2 text-2xl font-bold tracking-tight from-text-gray-900 dark:text-white">
			Hi, I'm Rishhi
		</h5>
			<p class="mb-3 font-normal text-gray-700 dark:text-gray-400 overflow-y-auto"
				in:fade={{ duration: 250 }}>
				{description}
			</p>
		<div class="flex justify-center items-center">
			<button
				on:click={setContact}
				class="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
			>
				<span
					class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
				>
					Say Hi
				</span>
			</button>
		</div>
	{:else}
	  <div class="spinner-item" title="Shadow">
		<Shadow size = "60"  color = "#3236a8" />
	  </div>
	{/if}
</div>
<style>
	.spinner-item {
		min-width: 200px;
		min-height: 150px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  	}
</style>