<script>
	import { setBackground } from '../../stores/stores';
	import { sendEmail } from './SendEmail';
	import { fade } from 'svelte/transition';
	import { clickOutside } from '../../click_outside/ClickOutside';

	let email = '';
	let checked = false;
	let showAlert = false;
	let success = false;
	let reason = '';

	const handleSend = async () => {
		if (email === '') {
			success = false;
			showAlert = true;
			reason = 'Email is blank';
			return;
		}
		success = true;
		try {
			const response = await sendEmail(email, checked);
			if (!response || !response.success) success = false;
		} catch (error) {
			success = false;
			reason = error.response;
		}
		showAlert = true;
	};
</script>

<div
	class="p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 opacity-95"
	use:clickOutside
	on:click_outside={setBackground}
	in:fade={{ duration: 250 }}
	out:fade={{ duration: 250 }}
>
	<h5 class="mb-2 text-2xl font-bold tracking-tight from-text-gray-900 dark:text-white">
		Contact me
	</h5>
	<p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
		My inbox is always open! Whether you have a question, or just wanted to say hi!
	</p>
	<div class="mb-6">
		<label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
			>Your email</label
		>
		<input
			bind:value={email}
			type="email"
			id="email"
			class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
			placeholder="example@gmail.com"
			required
		/>
	</div>
	<div class="flex items-center">
		<input
			bind:checked
			id="checkbox-all"
			type="checkbox"
			class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 -translate-y-1"
		/>
		<label for="checkbox-all" class="sr-only">checkbox</label>
		&nbsp;
		<p
			class="mb-3 font-normal text-gray-700 dark:text-gray-400"
			on:click={() => {
				checked = !checked;
			}}
		>
			&nbsp; Check me if you want my latest resume
		</p>
	</div>
	<div class="flex justify-center items-center">
		<button
			on:click={handleSend}
			class="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
		>
			<span
				class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
			>
				Send
			</span>
		</button>
	</div>
	{#if showAlert}
		{#if success}
			<div
				class="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800 opacity-97"
				role="alert"
			>
				<span class="font-medium">Success!</span> Email has been sent!
			</div>
		{:else}
			<div
				class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
				role="alert"
			>
				<span class="font-medium">{reason}!</span> Change a few things up and try submitting again.
			</div>
		{/if}
	{/if}
</div>
