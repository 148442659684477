import { writable } from "svelte/store";

export const nav = writable("HOME");

export const setHome = () => nav.set("HOME");
export const setContact = () => nav.set("CONTACT");
export const setBackground = () => nav.set("BACKGROUND");

export const description = writable({ title: "", description: "" });

export const setDescription = (name, text) => {
  description.set({ title: name, description: text });
  nav.set("DESCRIPTION");
};

export const isLoaded = writable(false);
